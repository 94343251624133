@import "./font.css";
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

* {
    --viewport-w: 90vw;
}

body {
    @apply mx-auto;
    max-width: var(--viewport-w);
}

.font-fira {
    font-family: 'Fira Code', monospace;
}

.info-card {
    @apply grid mx-5 items-center text-sm;
    grid-template-columns: min-content auto;
    grid-auto-rows: min-content;
    gap: 0.05em 0.5em;
}

.info-card a {
    @apply font-fira;
}

.subtitle {
    @apply select-none font-fira;
    position: absolute;
    top: 4.2rem;
    text-align: center;
    width: var(--viewport-w);
}

.title {
    @apply text-5xl pointer-events-none font-fira;
    white-space: pre-wrap;
    position: absolute;
    top: 0.5rem;
    transform: translateX(calc((var(--viewport-w) / 2) - 50%));
}

.main-page {
    visibility: hidden;
    opacity: 0;
}

.show {
    visibility: visible;
    animation: show;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
}

.skill {
    @apply flex max-w-full my-3 items-baseline flex-col;
}

.skill:not(:first-child) {
    @apply mt-10;
}

@screen md {
    .skill {
        @apply flex-row;
    }

    .skill:not(:first-child) {
        @apply mt-3;
    }
}

.skill p {
    @apply my-2;
}

.skill p:first-child {
    @apply mt-0;
}

.skill p:last-child {
    @apply mb-0;
}

.skill .prefix {
    @apply text-xl font-light mr-4 justify-self-start text-left;
}

@screen md {
    .skill .prefix {
        @apply justify-self-end text-right;
        width: 20%;
    }   
}

.skill .prefix.raw {
    @apply text-base;
}

@screen md {
    .skill .skill-info {
        width: 80%;
    }
}

.skill .skill-info .skill-title {
    @apply text-lg;
}

.skill .skill-info .skill-description {
    @apply text-sm;
}

.skills-section {
    max-width: 90ch;
}

.skills-section > section:first-of-type > h2 {
    @apply mt-0;
}

nav {
    @apply main-page uppercase grid grid-flow-col auto-cols-max gap-2 justify-center text-lg font-fira items-baseline;
}

main {
    @apply main-page grid mt-5 mb-10;
    grid-template-areas: "content";
    grid-template-rows: max-content;
}

main > section {
    @apply prose mx-auto;
    max-width: 80ch;
    grid-area: content;
}

nav span {
    @apply text-gray-200 pointer-events-none;
}

a {
    @apply cursor-pointer text-blue-700;
}

a:hover {
    @apply text-blue-500;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    @apply bg-transparent;
}

::-webkit-scrollbar-thumb {
    @apply bg-gray-300;
}

::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-400;
}

.projects-section {
    max-width: 100ch;
}

.project {
    @apply grid items-center text-sm border-b border-gray-300 border-solid mb-4 pb-4;
    grid-template-columns: auto;
    grid-template-rows: repeat(4, auto);
    grid-template-areas: 
        "image"
        "title"
        "links"
        "desc";
}

@screen md {
    .project {
        grid-template-columns: max-content 1fr;
        grid-template-rows: repeat(3, auto);
        grid-template-areas: 
            ". title"
            ". links"
            "image desc";
    }
}

.project:first-of-type {
    @apply border-t pt-10;
}

.project:last-of-type {
    @apply border-b pb-10;
}

.project .project-title {
    @apply mt-0 mb-2 text-xl;
    grid-area: title;    
}

.project .project-links {
    @apply my-0 list-none;
    grid-area: links;
}

.project .project-links li {
    @apply pl-0 my-1;
}

.project .project-links li::before {
    @apply hidden;
}

.project .project-links .linklogo {
    @apply mr-2;
}

.project .project-image {
    @apply my-0 mr-4;
    grid-area: image;
}

.project .project-desc {
    grid-area: desc;
}

@keyframes show {
    0% {
        opacity: 0;
        transform: translateY(1rem);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media print {
    nav {
        @apply hidden;
    }

    .page-title {
        @apply hidden;
    }

    .person-card {
        @apply mt-0;
    }
    
    .main {
        @apply text-sm;
    }

    .skill {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }

    h2 {
        page-break-after: avoid;
    }

    .skill-item {
        page-break-inside: avoid
    }

    .hide-print {
        @apply hidden;
    }
}